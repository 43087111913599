// const imageBasePath =
//   process.env.NODE_ENV === "development"
//     ? `http://localhost:3680`
//     : `https://api-lazma.b2gsoft.xyz`; // testing

const imageBasePath =
  process.env.NODE_ENV === "development" ? `http://localhost:7012` : `https://api.signaturebazarbd.com`;

// const imageBasePath = `https://apii.lazma.com`;

export default imageBasePath;
